.card {
  font-size: .875rem;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: 'Roboto', sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  background-color: #fff !important;
  color: #242424;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: .5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -.25em;
}
sup {
  top: -.5em;
}
a {
  color: #0B376F;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #3b94bf;
  text-decoration: underline;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #12447a;
}


.home_page_menu li::after {
  display: none;
}
.input-group-append .primary_btn{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: 400;
  font-size: 15px !important;
}
/* Custom Styles */
/* Home Page */

ul,li,button,
.MuiButton-root {
  font-family: 'Roboto', sans-serif !important;
}
.page-header {
  /* background: linear-gradient(149.84deg, #09336C -2.31%, #419FC8 98.85%);   */
  /* background: linear-gradient(90deg,#3a3480,#1d4e84); */
  background: url("../src/assets/images/banner_lines.png") no-repeat center;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  margin: 0;
  padding: 120px 0 60px;
  border: 0;
  display: flex;
  align-items: center;
  position: relative;

}

.page-header h1 {
  font-weight: 700;
  font-size: 54px;
  line-height: 70px;
  color: #242424;
}
.page-header p {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  max-width: 70%;
  margin: 15px auto 30px;
}
button.primary_btn {
  background: linear-gradient(93.99deg, #0B376F 1.25%, #3B94BF 93.54%);
  border-radius: 50px;
  border: 0px solid #0B376F;
  color: #fff;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  padding: 20px 45px;
  transition: 0.4s all;
}
button.primary_btn:hover {
  background: linear-gradient(93.99deg, #3B94BF 1.25%, #0B376F 93.54%);
}
.menu_btn {
  background: linear-gradient(93.99deg, #0B376F 1.25%, #3B94BF 93.54%);
  border-radius: 50px;
  color: #fff !important;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 15px;
  transition: 0.4s all;
  margin-left: 15px;
  min-width: auto;
}
.main_navbar {
  display: inline-flex;
  align-items: center;
}

.menu_btn:hover {
  background: linear-gradient(93.99deg, #3B94BF 1.25%, #0B376F 93.54%) !important;
  box-shadow: 0px 0px 0px 0px #852c00;
  color: #fff !important;
  text-shadow: 0 0 0 rgb(0 0 0 / 50%);
}
.main_navbar li a,
.main_navbar li {
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  color: #3a3480;
  text-transform: capitalize;
  white-space: nowrap;
}
.main_navbar li a:hover{
  text-decoration: none;
  background-color: transparent;
  color: #3B94BF;
}
.main_navbar li.menu_dropdown button {
  background-color: transparent;
  box-shadow: none;
  font-size: 15px;
  font-weight: 400;
  padding: 12px;
}
.menu_dropdown ul li:hover {
  background-color: #850303;
}
.menu_dropdown ul li a {
  color: #850303;
}
.menu_dropdown ul li a:hover {
  color: #fff;
}
.main_title {
  font-size: 44px;
  font-weight: 700;
}
.menu_btn.tableButton{
  font-size: 12px;
  margin-left: 0;
  border: 0;
  box-shadow: none;
  padding: 8px 15px;
  font-weight: 400;
}
.nav-fill .nav-item, .nav-fill > .nav-link{
  color: #3B94BF !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  background: linear-gradient(93.99deg, #0B376F 1.25%, #3B94BF 93.54%) !important;
  color: #ffffff !important;
}
.tokenomics_section p {
  font-size: 20px;
  color: #b0b0b0;
  font-weight: 500;
  margin-bottom: 0;
}
.tokenomics_section h3 {
  font-size: 24px;
  color: #d75d20;
  font-weight: 700;
  margin: 0;
}
.tokenomics_section_left > div {
  text-align: right;
  margin-bottom: 30px;
}
.tokenomics_section_right > div {
  text-align: left;
  margin-bottom: 30px;
}
.fees_table div {
  display: flex;
  padding: 10px 15px;
  border-radius: 5px;
}
.fees_table div label {
  color: #b0b0b0;
  font-size: 20px;
  font-weight: 500;
  width: 40%;
  margin-bottom: 0;
}
.fees_table div label:nth-child(2) {
  color: #d75d20;
}
.fees_table div:nth-child(odd) {
  background: #060606;
}
.chart_section {
  background: #510f01;
  position: relative;
  padding-top: 50px;
}
.token_details_list li {
  color: #fefefe;
  font-size: 24px;
  font-weight: 600;
  list-style: none;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  position: relative;
}
.token_details_list li::before {
  content: "";
  background: linear-gradient(90deg, #2c8615 0%, #46aa2c 100%);
  border: 1px solid #59c23e;
  width: 30px;
  height: 30px;
  position: absolute;
  left: -45px;
  border-radius: 5px;
}
.token_details_list li:nth-child(2)::before {
  background: linear-gradient(90deg, #b51e07 0%, #dd4615 100%);
  border: 1px solid #f7602f;
}
.token_details_list li:nth-child(3)::before {
  background: linear-gradient(90deg, #959700 0%, #c2c400 100%);
  border: 1px solid #d1d300;
}
.token_details_list li:nth-child(4)::before {
  background: linear-gradient(90deg, #016a84 0%, #18b1cb 100%);
  border: 1px solid #1eb9d3;
}
.token_details_list li:nth-child(5)::before {
  background: linear-gradient(90deg, #0e1086 0%, #1f21c0 100%);
  border: 1px solid #2425db;
}
.token_details_list li:nth-child(6)::before {
  background: linear-gradient(90deg, #ba5618 0%, #d86e2c 100%);
  border: 1px solid #a94507;
}
.token_details_list li:nth-child(7)::before {
  background: linear-gradient(90deg, #c3b62c 0%, #d3c637 100%);
  border: 1px solid #aca022;
}
.roadmap_section {
  background: #872d00;
  position: relative;
}
.main-timeline{
  margin: 20px 0;
  position: relative;
}
.main-timeline:before{
  content: "";
  display: block;
  width: 30px;
  height: 100%;
  background: #000;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 12px;
}
.main-timeline .timeline{
  margin-bottom: 40px;
  position: relative;
  cursor: default;
}
.main-timeline .timeline:after{
  content: "";
  display: block;
  clear: both;
}
.main-timeline .icon{
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: auto;
  position: absolute;
  top: 42px;
  left: 0;
  /* bottom: 0; */
  right: 0;
  z-index: 10;
}
.main-timeline .icon:before,
.main-timeline .icon:after{
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.33s ease-out 0s;
}
.main-timeline .icon:before{
  background: linear-gradient(90deg, #852c00 0%, #ff9600 100%);
  border: 2px solid #000;
  left: -3px;
}
.main-timeline .icon:after{
  border: 2px solid #c26100;
  left: 3px;
}
.main-timeline .timeline:hover .icon:before{
  left: 3px;
}
.main-timeline .timeline:hover .icon:after{
  left: -3px;
}
.main-timeline .date-content{
  width: 50%;
  float: left;
  position: relative;
}
.main-timeline .date-content:before{
  content: "";
  width: 32%;
  height: 3px;
  background: #c26100;
  margin: auto 0;
  position: absolute;
  top: 50px;
  right: 10px;
  /* bottom: 0; */
}
.main-timeline .date-outer{
  width: 330px;
  height: 230px;  
  margin: auto;
  z-index: 1;
}
.main-timeline .date-outer:before,
.main-timeline .date-outer:after{
  content: "";
  width: 330px;
  height: 230px;
  margin: 0 auto;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.33s ease-out 0s;
}
.main-timeline .date-outer:before{
  background-image: linear-gradient(90deg,#000 0,#000);
  left: -6px;
}
ul.timeline_list {
  padding: 15px 15px 0 35px;
  z-index: 2;
  position: relative;
}
.timeline_list li {
  font-size: 18px;
  color: #fff;
  list-style: none;
  position: relative;
  margin-bottom: 5px;
}
.count_pos {
  position: absolute;
  font-size: 250px;
  font-weight: 700;
  top: -40px;
  right: 0;
  color: #363636;
}
.timeline_list li::before {
  content: "";
  background: url("../src/assets/images/arrow_icon.svg");
  width: 10px;
  height: 16px;
  position: absolute;
  left: -20px;
  top: 4px;
  background-size: cover;
}
/* .main-timeline .date-outer:after{
  border: 2px solid #c0c0c0;
  left: 6px;
} */
.main-timeline .timeline:hover .date-outer:before{
  left: 6px;
}
.main-timeline .timeline:hover .date-outer:after{
  left: -6px;
}
.main-timeline .date{
  width: 330px;
  height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: left;
  overflow: hidden;
}
.main-timeline .month{
  font-size: 18px;
  font-weight: 700;
  color: #111;
}
.main-timeline .year{
  display: block;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  line-height: 36px;
}
.main-timeline .timeline-content{
  width: 50%;
  padding: 40px 0 20px 50px;
  float: right;
  text-align: left;
}
.main-timeline .title{
  font-size: 19px;
  font-weight: 700;
  color: #111;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0 0 15px 0;
}
.main-timeline .description{
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
}
.main-timeline .timeline:nth-child(2n) .date-content{
  float: right;
}
.main-timeline .timeline:nth-child(2n) .date-content:before{
  left: 10px;
}
.main-timeline .timeline:nth-child(2n) .timeline-content{
  padding: 40px 50px 20px 40px;
  text-align: right;
}
.padding_para {
  padding: 0 200px;
}
.token_section {
  background-color: #000;
}
.faq_panel .MuiAccordionSummary-root {
  flex-direction: row-reverse;
}
.faq_panel .MuiPaper-root {
  color: #fff;
  background-color: transparent;
  box-shadow: none;
  margin: 0 !important;
  border-bottom: 1px solid #111111;
  padding: 12px 0;
}
h2.accordian_head {
  font-size: 20px;
  font-weight: 700;
  transition: 0.4s all;
  margin: 0;
}
p.accordian_para {
  line-height: 28px;
  transition: 0.4s all;
  padding-left: 60px;
  font-size: 16px;
}
.accordian_para,
.accordian_head {
  position: relative;
}
.faq_panel .MuiAccordion-root:before {
  display: none;
}
.faq_panel .MuiIconButton-root {
  margin-right: 10px !important;
}
.faq_panel .MuiAccordionSummary-content {
  margin: 0 !important;
}
footer {
  background: linear-gradient(3deg, #000 0%, #070707 100%);
}
.footer_social_links li {
  margin: 10px;
}
.footer_social_links {
  margin-bottom: 20px;
}
.footer_social_links li a {
  background: #d75d20;
  border-radius: 10px;
  box-shadow: 2px 3px 0px 2px #852c00;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  transition: 0.4s all;
  text-shadow: 2px 4px 3px rgb(0 0 0 / 50%);
  color: #fff !important;
}
.footer_social_links li a:hover {
  background: #d75d20;  
  box-shadow: 0px 0px 0px 0px #852c00;
  color: #fff;
}
.copyright_txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #240f65;
  padding: 15px 0 5px;
}
.copyright_txt p {
  font-size: 14px;
}
.copyright_txt ul li a {
  font-size: 14px;
  padding: 5px 20px 0 0;
}
.copyright_txt ul li a:hover {
  color: #770202;
}
.copyright_txt ul li a::after {
  content: "";
  background: #fff;
  position: absolute;
  width: 1px;
  height: 13px;
  right: 9px;
  top: 10px;
}
.copyright_txt ul li:last-child a::after {
  display: none;
}
.Cursor {
  display: none;
}


/* New Css */
button {
  outline: none !important;
}
.fullwidth_box {
  background: #FFFFFF;
  border-radius: 15px;
  padding: 30px 50px;
  text-align: center;
}
.primary_modal .modal-title {
  color: #333;
}
.connectwallet_list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.connectwallet_list li {  
  background: linear-gradient(149.84deg, #09336C -2.31%, #419FC8 98.85%);
  margin: 10px 0;
  padding: 12px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s all;
}
.connectwallet_list li:hover {
  background: linear-gradient(149.84deg, #419FC8 -2.31%, #09336C 98.85%);
}
.connectwallet_list li img {
  width: 42px;
  margin-right: 10px;
}
.connectwallet_list li span {
  font-size: 18px;
  color: #fff;
}
.page-header-buy {
  min-height: 100vh;
  padding: 120px 0 60px;
  background: #fff;
  margin: 0;
  border: 0;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}
.page-header-buy h1 {
  font-weight: 700;
  font-size: 64px;
  line-height: 74px;
  color: #242424;
  margin: 15px 0 30px;
}
.page-header-buy h1 span {
  background: linear-gradient(95.83deg, #0B3870 31.96%, #3F9CC6 80.56%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.page-header-buy p {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
.buy_box {
  position: relative;
  z-index: 1;
  /* background: linear-gradient(149.84deg, #09336C -2.31%, #419FC8 98.85%); */
  background: linear-gradient(90deg,#3a3480,#1d4e84);
  border-radius: 15px;
  padding: 50px;
  overflow: hidden;
}
.buy_box::before {
  content: "";
  background: url("../src/assets/images/banner_lines.png") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.25;
}
.buy_box_inner {
  background: #FFFFFF;
  border-radius: 15px;
  padding: 30px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.buy_box_inner label {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #8C8C8C;
}
.buy_box_inner .form-control {
  border-radius: 50px;
  height: 46px;
  box-shadow: none !important;
}
.buy_box_inner .form-control {
  background: url("../src/assets/images/dropdown_icon_select.png") no-repeat center right 15px;
}
.buy_box_inner input {
  background: #F0F0F0 !important;
}
.buy_box_inner .input-group-text {
  border-radius: 50px;
}
.buy_box_inner button.primary_btn {
  width: 100%;
  padding: 12px;
  font-size: 20px;
  line-height: 23px;
}
.buy_box_inner small {
  color: #8C8C8C;
}
.buy_box_inner h4 {
  text-align: center;
  color: #0d3b72;
  margin-bottom: 30px;
}
.btn_grps {
  display: flex;
  align-items: center;
}
.btn_grps button+button {
  margin-left: 10px;
}
.report_filter {
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
}
.report_filter input {
  height: 24px;
  padding: 12px 14px;
}
.report_filter button {
  background: linear-gradient(93.99deg, #0B376F 1.25%, #3B94BF 93.54%);
  color: #fff;
  margin-left: 10px;
  padding-bottom: 1px;
}
a.menu_btn.user_address {
  display: flex;
  align-items: center;
}
a.menu_btn.user_address span {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
}
.MuiTablePagination-root p {
  font-size: 14px;
  margin: 0;
}
.report_filter .MuiFormControl-root:first-child {
  margin-right: 15px;
}
.report_filter .MuiFormControl-root button {
  background: transparent;
  margin-left: 0;
  padding: 0 10px 0 3px;
}
.report_filter .MuiFormControl-root input {
  background: #fff !important;
}
.report_filter .MuiFormControl-root button svg path {
  fill: #3b94bf;
}
.buy_box_inner h2 {
  color: #3a3480;
  font-size: 24px;
  margin-bottom: 30px;
}
.partner_dash_box {
  background: #378db9;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 3px -2px, rgba(0, 0, 0, 0.04) 0px 3px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 8px 0px !important;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.partner_dash_box > div {
  display: flex;
  align-items: center;
}
.partner_dash_box img {
  width: 42px;
  margin-right: 15px;
}
.partner_dash_box h2 {
  margin-bottom: 0;
  font-size: 24px;
  color: #fff;
}
.partner_dash_box h3 {
  margin-bottom: 0;
  font-size: 18px;
  color: #fff;
}
.partner_dash_box i.bi {
  font-size: 30px;
  cursor: pointer;
}
.partner_dash_box a {
  color: #d9e3ff;
}
.dash_graph_wrap {
  margin-top: 30px;
  color: #222;
}
.dash_graph_wrap h3 {
  color: #104077;
  font-size: 20px;
}


/* Media Queries */
@media screen and (max-width: 1279px) {
  .main_navbar li a {
    margin-left: 0;
  }
  .main_navbar li {
    float: none;
  }
  button.menu_btn {
    margin-left: 0;
    margin-bottom: 20px;
  }
  .home_page_menu .main_navbar{
    flex-direction: column !important;
    justify-content: center;
    width: 100%;
  }
  .home_page_menu .main_navbar li{
    display: flex !important;
    width: 100%;
  }
  .home_page_menu .main_navbar li a{
    display: block;
    width: 100%;
    text-align: center;
  }
  .home_page_menu .main_navbar li .menu_btn{
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 991px) {
  .main-timeline .date-outer:before, .main-timeline .date-outer:after {
    width: 280px;
    height: 200px;
  }
  .main-timeline .date {
    width: 280px;
  }
  .main-timeline .date-outer {
    width: 280px;
    height: 200px;
  }
  .count_pos {    
    font-size: 200px;
    top: -25px;
  }
  .padding_para {
    padding: 0;
  }
  .page-header-buy {    
    padding: 50px 0;
  }
}
@media screen and (max-width: 959px) {
  button.menu_btn {
    margin-bottom: 15px;
  }
  .page-header {
    text-align: center;
  }
  .subscribe_inp {
    width: 100%;
  }
  .padding_para {
    padding: 0;
  }
  .tokenomics_section_left > div {
    text-align: left;
  }
  .main_navbar li a {
    margin: 0 !important;
  }
  .home_page_menu .main_navbar li .menu_btn{
    margin-top: 15px !important;
    margin-bottom: 0px !important;
  }
  .main_navbar li {
    margin: 3px 10px;
  }
}
@media screen and (max-width: 767px) {  
  .page-header h1 {
    font-size: 40px;
    line-height: 50px;
  }
  .page-header p {
    font-size: 18px;
  }
  .main_title {
    font-size: 24px;
  }
  p {
    font-size: 14px;
  }
  .about_section img {
    margin-bottom: 30px;
  }
  .copyright_txt {
    display: block;
  }
  .footer_social_links li a {
    width: 60px;
    height: 60px;
  }  
  .main-timeline::after {
    left: 20px;
    right: unset;
  }
  .page-header-buy h1 {
    font-size: 36px;
    line-height: 42px;
  }
  .fullwidth_box {
    padding: 30px;
  }
}
@media screen and (max-width: 540px) {
  .page-header-buy h1 {    
    font-size: 32px;
    line-height: 42px;
  }
  .page-header h1 {
    font-size: 30px;
    line-height: 38px;
  }
  .buy_box,
  .buy_box_inner {    
    padding: 15px;
  }
  .buy_box_inner label {
    font-size: 16px;
  }
  button.primary_btn {    
    font-size: 20px;    
    padding: 12px 45px;    
  }
  .page-header {
    padding: 100px 0;
    height: 100%;
  }
}